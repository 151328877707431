import React from "react";
import { Link, useLocation } from "react-router-dom";
import pageLogo from "../../assets/images/png/page_logo.png";

const Header = () => {
  const location = useLocation();
  const path = location.pathname;

  console.log(location);
  const handldeOverlayActive = () => {
    document.body.classList.toggle("active-nav-overlay");
  };

  return (
    <>
      <header className="sticky_nav position-fixed top-0 start-0 w-100 bg-white">
        <section
          className="position-relative z-index-1 d-flex w-100 navbar-wrapper bg-white"
          id="home"
        >
          <div className="navbar-wrapper w-100 position-relative">
            <div className="container modified_container d-none d-lg-block">
              <div className="d-flex align-items-center justify-content-between py-4">
                <Link className="text-decoration-none" to="/">
                  <img className="hero_logo_mw" src={pageLogo} alt="" />
                </Link>
                <div className="nav-list-items d-flex">
                  <ul className="list-unstyled d-flex align-items-center mb-0">
                    <li className="ms-4 ms-xl-5">
                      <Link
                        className={`text-decoration-none navbar-link navbar_hover color_black font_sm fw-normal opacity-75 ${
                          path === "/" ? "color_orange" : ""
                        }`}
                        to="/"
                      >
                        Ccenter Tech
                      </Link>
                    </li>
                    <li className="ms-4 ms-xl-5">
                      <Link
                        className={`text-decoration-none navbar-link navbar_hover color_black font_sm fw-normal opacity-75 ${
                          path === "/engagements" ? "color_orange" : ""
                        }`}
                        to="/engagements"
                      >
                        Engagements
                      </Link>
                    </li>

                    <li className="ms-4 ms-xl-5">
                      <Link
                        className={`text-decoration-none navbar-link navbar_hover color_black font_sm fw-normal opacity-75 ${
                          path === "/partners" ? "color_orange" : ""
                        }`}
                        to="/partners                        "
                      >
                        Partners
                      </Link>
                    </li>
                    <li className="ms-4 ms-xl-5">
                      <Link
                        className={`text-decoration-none navbar-link navbar_hover color_black font_sm fw-normal opacity-75 ${
                          path === "/contact-us" ? "color_orange" : ""
                        }`}
                        to="/contact-us"
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="px-3 py-4 py-lg-0 d-flex justify-content-between align-items-center">
              <div className="nav-log cursor_pointer z-index_100">
                <Link to="/">
                  {" "}
                  <img
                    className="d-lg-none hero_logo_mw"
                    src={pageLogo}
                    alt="page-logo"
                  />
                </Link>
              </div>
              <div
                id="nav-icon1"
                className={`hamburger-menu`}
                onClick={() => handldeOverlayActive()}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>

            <div className={`navbar-overlay w-100 position-fixed d-lg-none`}>
              <ul className="h-100 list-unstyled d-flex flex-column align-items-center justify-content-center m-0">
                <li onClick={() => handldeOverlayActive()}>
                  <Link
                    className={`text-decoration-none navbar_hover main_para color_black opacity-75 ${
                      path === "/" ? "color_orange" : ""
                    }`}
                    to="/"
                  >
                    Ccenter Tech
                  </Link>
                </li>
                <li onClick={() => handldeOverlayActive()}>
                  <Link
                    className={`text-decoration-none navbar_hover main_para color_black opacity-75 ${
                      path === "/engagements" ? "color_orange" : ""
                    }`}
                    to="/engagements"
                  >
                    Engagements
                  </Link>
                </li>
                <li onClick={() => handldeOverlayActive()}>
                  <Link
                    className={`text-decoration-none navbar_hover main_para color_black opacity-75 ${
                      path === "/partners" ? "color_orange" : ""
                    }`}
                    to="/partners"
                  >
                    Partners
                  </Link>
                </li>
                <li onClick={() => handldeOverlayActive()}>
                  <Link
                    className={`text-decoration-none navbar_hover main_para color_black opacity-75  ${
                      path === "/contact-us" ? "color_orange" : ""
                    }`}
                    to="/contact-us"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </header>
    </>
  );
};

export default Header;
