import imgTrusted from "../../assets/images/svg/trusted-partner.svg";
import imgEnterprise from "../../assets/images/svg/Case-Studies.svg";
import imgStudies from "../../assets/images/svg/Enterprise-Grade-Consulting.svg";
import engment_card from "../../assets/images/svg/engment_card.svg";
import engment_card2 from "../../assets/images/svg/engment_card2.svg";
import engment_card3 from "../../assets/images/svg/engment_card3.svg";

export const homeCardData = [
  {
    imgurl: imgTrusted,
    heading: "Trusted Partner",
    para: "We are an authorized partner with AWS, Oracle, Orange and Integrated Research.",
    maxWidth: "247px",
  },
  {
    imgurl: imgEnterprise,
    heading: "Enterprise Grade Consulting",
    para: "With 16 years of experience in global enterprises, we offer Enterprise grade consulting services. We deliver great documentation and engineering technical guides.",
    maxWidth: "294px",
  },
  {
    imgurl: imgStudies,
    heading: "Case Studies",
    para: "We offer technology road-mapping engagements, which are based on thorough review of your enterprise's legal, technical and functional requirements.",
    maxWidth: "294px",
  },
];
export const engagementData = [
  {
    imgurl: engment_card,
    heading: "Professional Services ",
    para: "Design and implement advanced contact center integrations.",
    para1:
      "Prepare technology road-maps for modern contact center deployments.",
    para2:
      "Analyze total cost of ownership for contact center environments and identify cost saving opportunities",
    maxWidth: "247px",
    para3: "",
  },
  {
    imgurl: engment_card2,
    heading: "Managed Services",
    para: "Manage daily configuration changes",
    para1: "Integrate with change management processes",
    para2: "Manage break and fix scenarios",
    para3:
      "Monitor all UC components and integrate monitoring tools with ITSM platforms",
    maxWidth: "294px",
  },
  {
    imgurl: engment_card3,
    heading: "Telecommunications Expense Management Services ",
    para: "Manage telecommunications contracts",
    para1: "Automate contract renewals and disputes",
    para2: "Manage number porting activities",
    para3: "Analyze bill payment and identify cost saving opportunities",
    maxWidth: "294px",
  },
];
