import React from "react";
import Header from "../common/Header";

const EngagementHero = () => {
  return (
    <>
      <section className="engagement_hero d-flex flex-column align-items-center justify-content-center position-relative">
        <Header />
        <div className="container text-center">
          <h2 className="font_4xl text-white fw_medium mb-2 pb-1">
            We cater to all of your
            <span className="color_orange"> contact center </span>
            <br className="d-none d-sm-block" /> and telecommunications needs.
          </h2>
          <p className="font-sm fw-normal text-white mb-0">
            We tailor our services to your needs.
          </p>
        </div>
      </section>
    </>
  );
};

export default EngagementHero;
