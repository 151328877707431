import React from "react";
import Header from "../common/Header";

const ContactHero = () => {
  return (
    <>
      <section className="contact_hero d-flex flex-column align-items-center justify-content-center position-relative">
        <Header />
        <div className="container text-center">
          <h2 className="font_4xl text-white fw_medium mb-2 pb-1">
            Have a <span className="color_orange">project ?</span> <br /> We
            would love to hear from you.
          </h2>
          <p className="font-sm fw-normal text-white">
            Use the form below to give us an idea about your needs.
          </p>
        </div>
      </section>
    </>
  );
};

export default ContactHero;
