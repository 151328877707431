import React from "react";
import { Link } from "react-router-dom";
import Header from "../common/Header";

const Hero = () => {
  return (
    <div>
      <section className="hero_min_height homehero_bg d-flex flex-column align-items-center justify-content-center">
        <Header />
        <div className="container">
          <div className="row justify-content-center">
            <div className="text-center col-md-8 col-xl-7 col-xxl-6">
              <div>
                <h2 className="text-white font_4xl">
                  We are Cloud Contact Center
                  <span className="color_orange">&nbsp;Specialists</span>
                </h2>
                <p className="text-white mt-1 mb-lg-4 pt-2 font_sm fw-normal">
                  Specialized in Amazon Connect and Oracle Enterprise
                  Communications. We design and develop customized scenarios for
                  enterprises, who have complex requirements across multiple
                  geographies.
                </p>
                <Link
                  to="/contact-us"
                  className="common_btn text-white mt-lg-4 fw-normal font_md d-inline-block text-decoration-none"
                >
                  Request Consulting
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
