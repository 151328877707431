import React from "react";
import ContactHero from "../components/ContactUs/ContactHero";
import TouchWith from "../components/ContactUs/TouchWith";

const ContactUs = () => {
  return (
    <>
      <ContactHero />
      <TouchWith />
    </>
  );
};

export default ContactUs;
