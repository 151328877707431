import React from "react";
import Homepagecard from "../components/homepage/Homepagecard";
import OutstandingService from "../components/homepage/OutstandingService";
import About from "../components/homepage/About";
import Hero from "../components/homepage/Hero";

const Home = () => {
  return (
    <>
      <Hero />
      <Homepagecard />
      <OutstandingService />
      <About />
    </>
  );
};

export default Home;
