import React from "react";

const about = () => {
  return (
    <section className="mb-3 mb-lg-5 pb-5">
      <div className="container modified_container d-flex align-items-center">
        <div className="row flex-column-reverse flex-lg-row align-items-center justify-content-between">
          <div className="col-md-11 col-lg-7 pe-4 mb-4 mb-lg-0">
            <div className="row justify-content-center">
              <div className="col-sm-6">
                <div className="cost_card">
                  <p className="fw-bold font_lg mb-2">
                    Telecommunications Cost Reduction
                  </p>
                  <p className="fw-normal font_sm mb-0 ps-lg-1 pe-lg-2">
                    We partner with Orange Business Services and Oracle to
                    provide cost effective solutions. We offer professional and
                    managed services to your SIP infrastructure.
                  </p>
                </div>
                <div className="cost_card mt-4">
                  <p className="fw-bold font_lg mb-2">Automation First</p>
                  <p className="fw-normal font_sm mb-0">
                    We use Python for automating complex builds across multiple
                    environments (DEV, QA and Production)
                  </p>
                </div>
              </div>
              <div className="col-sm-6 mt-4 ">
                <div className="cost_card mt-2">
                  <p className="fw-bold font_lg mb-2">Fixed Price POC</p>
                  <p className="fw-normal font_sm mb-0 ps-lg-1">
                    We offer inclusive POC initiatives for low cost fixed price
                    contracts.
                  </p>
                </div>
                <div className="cost_card mt-4">
                  <p className="fw-bold font_lg mb-2">
                    Contact Center Security
                  </p>
                  <p className="fw-normal font_sm mb-0 ps-lg-1 pe-lg-2">
                    We design bullet proof contact center solutions, which keep
                    bad actors away from your IVR. In turn, your operational
                    costs will be reduced considering cloud consumption based
                    cost models. Less usage yields lower operational costs.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-10 col-lg-5 my-4 my-lg-0  ps-md-0 ps-md-2">
            <div className=" text-center text-lg-start">
              <h2 className="fw_medium lh-sm font_3xl color_black">
                Concerned about data residency and{" "}
                <span className="color_orange">sovereignty?</span>
              </h2>
              <p className="font_sm color_black opacity-07">
                We offer thorough contact center data designs to help your
                enterprise meet legal and compliance requirements in different
                countries.
              </p>
              <p className="font_sm color_black opacity-07">
                We are very familiar with consumer data privacy challenges in
                Healthcare, Financial and Retail markets.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default about;
