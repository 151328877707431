import React from "react";
import { Link } from "react-router-dom";
import footerLogo from "../../assets/images/png/fotr_logo.png";

const Footer = () => {
  const getCurrentYear = new Date().getFullYear();
  return (
    <>
      <section className="bg_gray">
        <div className="min_vh_fotr d-flex align-items-center justify-content-center border-1 border-bottom">
          <div className="text-center footer_margin_top py-5 py-md-0">
            <Link to="/">
              <img
                src={footerLogo}
                alt="footer-logo-img"
                className="footer_logo_width"
              />
            </Link>
          </div>
        </div>
        <div className="text-center">
          <p className="cursor_pointer mb-0 py-3 font_xs color_gray004">
            @copyright<span>{getCurrentYear}</span>
          </p>
        </div>
      </section>
      {/* <section className="bg_gray003">
        <div className=" min_vh_fotr d-flex justify-content-center align-items-end">
          <div className="text-center">
            <img
              src={footerLogo}
              alt="footer-logo-img"
              className="logo_fotr footer_logo_width"
            />
          </div>
        </div>
      </section>
      <hr className="my-0" />
      <div className="bg_gray003">
        <div className="container">
          <div className="text-center">
            <p className="py-3 font_xs color_gray004 mb-0">@copyright2022</p>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Footer;
