import emailjs from "@emailjs/browser";
import React, { useState } from "react";
import { toast } from "react-toastify";
import LoaderSpiner from "./LoaderSpinner";
emailjs.init("Vw9qWFasq0RCUFJ2g");

const Contact = () => {
  const disallowedDomains = [
    "gmail.com",
    "outlook.com",
    "yahoo.com",
    "aol.com",
    "hotmail.com",
    "icloud.com",
    "me.com",
    "msn.com",
    "live.com",
    "yandex.com",
    "zoho.com",
  ];

  const [email, setEmail] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");

  function handleSumbit(event) {
    event.preventDefault();
    if (isValidEmail(email)) {
      setLoading(true);
      emailjs
        .send("ccenter-tech.dev", "template_f4krvye", {
          email: email,
        })
        .then(() => {
          setLoading(false);
          setEmail("");
          toast("We will get in touch with you shortly!", {
            position: "bottom-right",
            autoClose: 4000,
          });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast("Failed to submit! Please try again.", {
            position: "bottom-right",
            autoClose: 4000,
          });
        });
    }
  }

  const isValidEmail = (email) => {
    const re =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    if (!re.test(String(email).toLowerCase())) {
      setEmailError("Please enter a valid business email!");
      return false;
    }
    if (disallowedDomains.some((domain) => email.endsWith(`@${domain}`))) {
      setEmailError(`${email} is not a valid business email.`);
      return false;
    }
    setEmailError("");
    return true;
  };

  return (
    <>
      <section className="px-2 mx-1 px-md-0 mx-md-0">
        <div className="container  modified_container ready_bg p-3 p-sm-4 p-sm-4 p-md-5 index_1">
          <div className="row justify-content-center">
            <div className="col-md-11 col-lg-8 col-xl-9">
              <div className="text-center">
                <p className="fw_medium font_2xl text-white lh-sm mb-2 mb-sm-3">
                  Ready to take your contact center to the next level ?
                </p>
                <p className="font_sm text-white pb-2 mb-0">
                  Get in touch using the below link and someone will reach out
                  to you promptly!
                </p>
                <form
                  onSubmit={handleSumbit}
                  className="mb-2 w-75 mx-auto bg-white d-flex justify-content-between align-items-center email_box mt-2 mt-sm-4 d-inline-block"
                >
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                    className="input_border font_sm w-100"
                    type="email"
                    placeholder="Enter Your Email"
                  />
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="btn_subscribe w-170 text-white fw_semibold font_md"
                  >
                    {isLoading ? <LoaderSpiner /> : "Contact"}
                  </button>
                </form>
                {emailError && email.length > 0 && (
                  <span className="text-error">{emailError}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
