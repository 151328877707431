import React from "react";
import { engagementData } from "../common/Helper";

const EngagementCard = () => {
  return (
    <>
      <section className="negative_margin_hero_cards index_1 mb-5 pb-4 pb-xl-5 py-0 pt-xl-5 pt-xxl-0">
        <div className="container modified_container mb-lg-5 pb-xl-4">
          <div className="row">
            {engagementData.map((obj, index) => (
              <div
                className="col-sm-8 col-md-6 col-lg-4  pt-lg-0 m-auto m-lg-0 mt-4 mt-lg-0"
                key={index}
              >
                <div className="bg-white engage_card d-flex align-items-center flex-column">
                  <img src={obj.imgurl} alt="imgurl" />
                  <h3 className="pt-3 mb-md-3 text-center font_xl color_black fw-bold">
                    {obj.heading}
                  </h3>
                  <ul>
                    <li
                      className=" m-0 lh_29 color_black opacity-75 font_sm fw-normal"
                      style={{ maxWidth: obj.maxWidth }}
                    >
                      {obj.para}
                    </li>
                    <li
                      className=" m-0 lh_29 color_black opacity-75 font_sm fw-normal"
                      style={{ maxWidth: obj.maxWidth }}
                    >
                      {obj.para1}
                    </li>
                    <li
                      className=" m-0 lh_29 color_black opacity-75 font_sm fw-normal"
                      style={{ maxWidth: obj.maxWidth }}
                    >
                      {obj.para2}
                    </li>
                    <li
                      className={` m-0 lh_29 color_black opacity-75 font_sm fw-normal ${
                        obj.para3 === "" ? "list-unstyled" : ""
                      }`}
                      style={{ maxWidth: obj.maxWidth }}
                    >
                      {obj.para3}
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default EngagementCard;
