import React from "react";
import { homeCardData } from "../common/Helper";

const Homepagecard = () => {
  return (
    <div>
      <section className="negative_margin_hero_cards py-0 pt-xl-5 pt-xxl-0">
        <div className="container modified_container">
          <div className="row">
            {homeCardData.map((obj, index) => (
              <div
                className="col-sm-10 col-md-6 col-lg-4 m-auto m-lg-0"
                key={index}
              >
                <div className="bg-white hero_card text-center d-flex align-items-center flex-column py-4 px-2 px-lg-4 mt-4 mt-md-5 mt-lg-0">
                  <img src={obj.imgurl} alt="homapge-cards-img" />
                  <h3 className="pt-3 mb-0 font_xl color_black fw-bold">
                    {obj.heading}
                  </h3>
                  <p
                    className="pt-3 m-0 card_para_mw lh_29 color_black opacity-75 font_sm fw-normal"
                    style={{ maxWidth: obj.maxWidth }}
                  >
                    {obj.para}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Homepagecard;
