import React from "react";
import talantedResources from "../../assets/images/png/outstandingservice-img.png";
import amazoneConnect from "../../assets/images/png/amazone-connect.png";
import oracel from "../../assets/images/png/oracel.png";

const OutstandingService = () => {
  return (
    <div>
      <section className="py-5 mt-4 my-lg-5">
        <div className="container modified_container py-4">
          <div className="row justify-content-center justify-content-lg-between">
            <div className="col-sm-7 col-lg-5 text-center text-sm-start">
                <div>
                <img
                  className="w-100 talented_img_border"
                  src={talantedResources}
                  alt="talented-resources"
                />
              </div>
            </div>
            <div className="col-lg-7 mt-4 mt-lg-0">
              <div className="ps-lg-5">
                <h2 className="color_black fw_500 font_3xl text-center text-lg-start">
                  Talented <span className="color_orange">Resources,</span>{" "}
                  Competitive Pricing and{" "}
                  <span className="color_orange">Outstanding</span> service.
                </h2>
                <div className="d-flex flex-column flex-md-row justify-content-between text-center text-md-start pt-2 mt-md-1">
                  <div className="mx-auto ">
                    <img
                      className="mw_amazone_oracel"
                      src={amazoneConnect}
                      alt="amazone-connect"
                    />
                    <h5 className="color_black fw_medium font_xl pt-3">
                      Awards Winning Company
                    </h5>
                    <p className="amazone_connect_para_mw m-0">
                      We have been working with Amazon Connect since its market
                      release in 2017. We have multiple proven solutions for
                      integration and customization challenges, which arise with
                      deploying Amazon Connect in complex environments.
                    </p>
                  </div>
                  <div className="ps-md-4 mt-3 mt-lg-3 mx-auto">
                    <img
                      className="mw_amazone_oracel"
                      src={oracel}
                      alt="amazone-connect"
                    />
                    <h5 className="color_black fw_medium font_xl mt-3">
                      Startup IT Solution & Business Dev
                    </h5>
                    <p className="amazone_connect_para_mw">
                      We introduce Oracle Communications products to Amazon
                      Connect deployments. We offer innovative yet customized
                      call authentication and multi-vendor fail-over to contact
                      center environments.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OutstandingService;
