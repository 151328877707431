import React, { useEffect, useState } from "react";
import location from "../../assets/images/svg/location.svg";
import phone from "../../assets/images/svg/contact.svg";
import mail from "../../assets/images/svg/mail.svg";
import LoaderSpiner from "../common/LoaderSpinner";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
emailjs.init("Vw9qWFasq0RCUFJ2g");

const TouchWith = () => {
  const initailState = {
    businessemail: "",
    number: "",
    topic: "",
    message: "",
  };
  const disallowedDomains = [
    "gmail.com",
    "outlook.com",
    "yahoo.com",
    "aol.com",
    "hotmail.com",
    "icloud.com",
    "me.com",
    "msn.com",
    "live.com",
    "yandex.com",
    "zoho.com",
  ];

  const [isError, setError] = useState(false);
  const [isPhoneValid, setPhoneValid] = useState(false);
  const [PhoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [touchWith, setTouchWith] = useState(initailState);

  function handleInitialState() {
    setLoading(false);
    setTouchWith(initailState);
    setEmailError("");
    setPhoneError("");
    setPhoneValid(false);
    setError(false);
  }

  const handleSubmitTouchWith = () => {
    if (
      PhoneError.length === 0 &&
      touchWith.businessemail.length > 0 &&
      touchWith.number.length > 0 &&
      touchWith.topic.length > 0 &&
      touchWith.message.length > 0
    ) {
      if (isValidEmail(touchWith.businessemail)) {
        setLoading(true);
        emailjs
          .send("ccenter-tech.dev", "template_ie5i0kn", {
            businessemail: touchWith.businessemail,
            phone: touchWith.number,
            topic: touchWith.topic,
            message: touchWith.message,
          })
          .then(() => {
            handleInitialState();
            toast.success("We will get in touch with you shortly!", {
              position: "bottom-right",
              autoClose: 4000,
            });
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            toast.error("Failed to submit! Please try again.", {
              position: "bottom-right",
              autoClose: 4000,
            });
          });
      }
    } else {
      setError(true);
      if (touchWith.number.length === 0) {
        setPhoneError("Phone number is required!");
        setPhoneValid(true);
      }
    }
  };

  useEffect(() => {
    if (touchWith.number.length > 0) {
      var filter =
        /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;
      let validate;
      if (filter.test(touchWith.number)) {
        if (touchWith.number.length == 10) {
          validate = true;
          setPhoneValid(true);
        } else {
          setPhoneError("Please put 10 digit mobile number!");
          validate = false;
          setPhoneValid(true);
        }
      } else {
        setPhoneError("Not a valid number!");
        validate = false;
        setPhoneValid(true);
      }
      if (validate) {
        setPhoneError("");
        setPhoneValid(false);
      }
    }
  }, [touchWith.number]);

  const isValidEmail = (email) => {
    const re =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    if (!re.test(String(email).toLowerCase())) {
      setEmailError("Please enter a valid business email!");
      console.log("here");
      return false;
    }
    if (disallowedDomains.some((domain) => email.endsWith(`@${domain}`))) {
      setEmailError(
        `${touchWith.businessemail} is not a valid business email.`
      );
      return false;
    }
    setEmailError("");
    return true;
  };
  return (
    <>
      <section
        className="negative_margin_hero_cards index_8 mb-5 pb-3 pb-lg-5"
        id="contact_card"
      >
        <div className="container modified_container">
          <div className="contact_box p-3 p-sm-4 p-md-5 p mb-4 mb-lg-5">
            <div className="row justify-content-lg-between">
              <div className="col-lg-6">
                <div className="w-full">
                  <p className="text-center fw_medium font_5xl mb-2">
                    Get In Touch <span className="color_orange">With Us</span>
                  </p>
                  <div className=" mx-auto bg-white mt-sm-4">
                    <div className="mb-2">
                      <input
                        value={touchWith.businessemail}
                        onChange={(event) =>
                          setTouchWith({
                            ...touchWith,
                            businessemail: event.target.value,
                          })
                        }
                        className="contact_input borer border-top-0 border-end-0 border-start-0 mb-1 font_sm w-100"
                        type="email"
                        placeholder="Business email"
                      />
                      {isError && touchWith.businessemail.length === 0 && (
                        <span className="text-error">
                          Business Email is required!
                        </span>
                      )}
                      {emailError && touchWith.businessemail.length > 0 && (
                        <span className="text-error">{emailError}</span>
                      )}
                    </div>

                    <div className="d-flex align-items-start mb-3">
                      <div className="w-100 d-flex flex-column">
                        <select
                          onChange={(event) =>
                            setTouchWith({
                              ...touchWith,
                              topic: event.target.value,
                            })
                          }
                          className="w-full contact_input border border-top-0 border-end-0 border-start-0 me-1 me-sm-2 font_sm"
                        >
                          <option value="Select Topic" className="font_sm">
                            Select Topic
                          </option>
                          <option
                            value="Cloud Contact Center"
                            className="font_sm"
                          >
                            Cloud Contact Center
                          </option>
                          <option value="Oracle" className="font_sm">
                            Oracle
                          </option>
                          <option
                            value="Communications General"
                            className="font_sm"
                          >
                            Communications General
                          </option>
                        </select>
                        {isError && touchWith.topic.length === 0 && (
                          <span className="text-error">
                            Please select any topic!
                          </span>
                        )}
                      </div>
                      <div className="w-100 d-flex flex-column">
                        <input
                          value={touchWith.number}
                          onChange={(event) =>
                            setTouchWith({
                              ...touchWith,
                              number: event.target.value,
                            })
                          }
                          className="w-100 contact_input  border border-top-0 border-end-0 border-start-0  font_sm w_49"
                          type="number"
                          placeholder="Phone no."
                        />

                        {isPhoneValid &&
                          PhoneError &&
                          PhoneError.length > 0 && (
                            <span className="text-error">{PhoneError}</span>
                          )}
                      </div>
                    </div>
                    <textarea
                      value={touchWith.message}
                      onChange={(event) =>
                        setTouchWith({
                          ...touchWith,
                          message: event.target.value,
                        })
                      }
                      name="message"
                      id="message"
                      cols="30"
                      rows="1"
                      type="message"
                      placeholder="Message"
                      className="contact_input w-100 mb-2 border border-top-0 border-end-0 border-start-0"
                    ></textarea>
                    {isError && touchWith.message.length === 0 && (
                      <span className="text-error">Please fill Message!</span>
                    )}
                    <button
                      disabled={isLoading}
                      onClick={handleSubmitTouchWith}
                      className="btn_subscribe w-100 text-white fw_semibold font_md mt-xl-2"
                    >
                      {isLoading ? <LoaderSpiner /> : "Contact"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 mt-4 mt-lg-0">
                <div className="location_card d-flex mb-3">
                  <span className="p-2 bg_orange me-4 border_radius_location"></span>
                  <div className="d-flex align-items-start py-2">
                    <img src={location} alt="location_icon" />
                    <div className="ms-3">
                      <p className="mb-0 fw-semibold font_lg text-black">
                        Location:
                      </p>
                      <p className="mb-0 fw-normal font_sm opacity-75 text-black">
                        Ccenter Tech, LLC
                      </p>
                      <p className="mb-0 fw-normal font_sm opacity-75 text-black">
                        211 Baker Road, Unit 142
                      </p>
                      <p className="mb-0 fw-normal font_sm opacity-75 text-black">
                        Barker, TX 77413
                      </p>
                    </div>
                  </div>
                </div>
                <div className="location_card d-flex mb-3">
                  <span className="p-2 bg_orange me-4 border_radius_location"></span>
                  <div className="d-flex pb-sm-5 align-items-start py-2">
                    <img src={phone} alt="location_icon" />
                    <div className="ms-3">
                      <p className="mb-0 fw-semibold font_lg text-black">
                        Contact:
                      </p>
                      <a
                        href="tel:+1713-489-3810"
                        className="mb-0 fw-normal font_sm opacity-75 text-black text-decoration-none bg-opacity-75"
                      >
                        +1 713-489-3810
                      </a>
                    </div>
                  </div>
                </div>
                <div className="location_card d-flex">
                  <span className="p-2 bg_orange me-4 border_radius_location"></span>
                  <div className="d-flex pb-sm-5 align-items-start py-2">
                    <img src={mail} alt="location_icon" />
                    <div className="ms-3">
                      <p className="mb-0 fw-semibold font_lg text-black">
                        E-mail:
                      </p>
                      <a
                        href="mailto:contact@ccenter-tech.io"
                        className="mb-0 fw-normal font_sm opacity-75 text-black text-decoration-none"
                      >
                        contact@ccenter-tech.io
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TouchWith;
