import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./about.css";
import "./App.css";
import BackToTop from "./components/common/BackToTop";
import Contact from "./components/common/Contact";
import Footer from "./components/common/Footer";
import ContactUs from "./view/ContactUs";
import Engagements from "./view/Engagements";
import Home from "./view/Home";
import Partners from "./view/Partners";

function App() {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {" "}
      <ToastContainer />
      <BackToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/engagements" element={<Engagements />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
      <Contact />
      <Footer />
    </>
  );
}

export default App;
