import React from "react";

const LoaderSpiner = () => {
  return (
    <>
      <div className="snippet" data-title="dot-pulse">
        <div className="stage">
          <div className="dot-pulse mx-auto">1</div>
        </div>
      </div>
    </>
  );
};

export default LoaderSpiner;
