import React from "react";
import Header from "../common/Header";

const PrtnrHero = () => {
  return (
    <>
      {/* <section className="hero_min_height prtnr_bg d-flex flex-column">
        <Header />
        <div className="d-flex align-items-center justify-content-center flex-grow-1 py-5 mt-4 mb-5 py-lg-0 my-lg-0">
          <div className="container modified_container text-center">
            
          </div>
        </div>
      </section> */}
      <section className="prtnr_bg  d-flex flex-column align-items-center justify-content-center position-relative">
        <Header />
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-lg-9 col-xl-8 px-xxl-4">
              <div>
                <h2 className="text-white font_4xl">
                  Our partners are market leaders{" "}
                  <span className="color_orange">technology</span> vendors.
                </h2>
                <p className="text-white mt-1 mb-0 pt-2 px-lg-4 px-xl-5 mx-xl-5">
                  Our partner ecosystem allows us to provide you with next
                  generation solutions for your contact center and
                  telecommunications requirements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrtnrHero;
