import React from "react";
import EngagementCard from "../components/engagment/EngagementCard";
import EngagementHero from "../components/engagment/EngagementHero";

const Engagements = () => {
  return (
    <>
      <EngagementHero />
      <EngagementCard />
    </>
  );
};

export default Engagements;
