import React from "react";
import AwsPartnr from "../components/partners/AwsPartnr";
import PrtnrHero from "../components/partners/PrtnrHero";

const Partners = () => {
  return (
    <>
      <PrtnrHero />
      <AwsPartnr />
    </>
  );
};

export default Partners;
