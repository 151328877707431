import React from "react";
import aws from "../../assets/images/png/amazon.png";
import orange from "../../assets/images/png/orange.png";
import orcale from "../../assets/images/png/orcale.png";
import ir from "../../assets/images/png/ir.png";

const AwsPartnr = () => {
  return (
    <>
      <section className="negative_margin_hero_cards index_1 mb-sm-5 py-0 pt-xl-5 pt-xxl-0">
        <div className="container modified_container pb-xxl-5">
          <div className="row">
            <div className="col-sm-6 col-lg-3">
              <div className="aws_cards text-center mb-5 d-flex flex-column align-items-center justify-content-center">
                <img src={aws} alt="amazon" />
                <p className="font_xl fw-bolder mb-0 mt-4">
                  Amazon Web Services
                </p>
              </div>
            </div>
            <div className="col-sm-6 mt-3 mt-sm-0 col-lg-3">
              <div className="aws_cards text-center mb-5 d-flex flex-column align-items-center justify-content-center">
                <img src={orange} alt="amazon" />
                <p className="font_xl fw-bolder mb-0 mt-4">
                  Orange Communications
                </p>
              </div>
            </div>
            <div className="col-sm-6 mt-3 mt-lg-0 col-lg-3">
              <div className="aws_cards text-center mb-5 d-flex flex-column align-items-center justify-content-center">
                <img src={orcale} alt="amazon" />
                <p className="font_xl fw-bolder mb-0 mt-4">Oracle</p>
              </div>
            </div>
            <div className="col-sm-6 mt-3 mt-lg-0 col-lg-3">
              <div className="aws_cards text-center mb-5 d-flex flex-column align-items-center justify-content-center">
                <img src={ir} alt="amazon" />
                <p className="font_xl fw-bolder mb-0 mt-4">IR</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AwsPartnr;
